import React, {useState, useRef, useEffect} from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useSpring, animated } from 'react-spring'

import useIntersect from '../../utils/useIntersect'

import colors from "../../styles/colors"
import device from '../../styles/device'

const ProjectsItemContainer = styled.a`
    width: 50%;
    padding: 24px 10px;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;

    @media ${device.phone}{
        width: 100%;
        margin-top: 0 !important;
    }

    &:nth-of-type(4n + 4) {
        margin-top: ${props => props.width-props.width/0.83}px;
    }

    &:nth-of-type(4n + 1) .projectImage,
    &:nth-of-type(4n + 4) .projectImage {
        height: ${props => props.width/0.83}px;
    }

    &:nth-of-type(4n + 2) .projectImage,
    &:nth-of-type(4n + 3) .projectImage {
        height: ${props => props.width}px;
    }
`

const ProjectsItemContainerContent = styled.div`
    h2 {
        color: rgb(20, 20, 20);
        font-size: 16px;
        font-weight: bold;
        line-height: 29px;
        color: ${colors.tertiary};
        margin: 18px 0 0 0;
    }

    p {
        font-size: 14px;
        line-height: 25px;
        color: ${colors.tertiary};
        margin: 0;
    }

    .gatsby-image-wrapper, img {
        width: 100%;
        max-width: 100%;
        transform: scale(1);
        transition: transform .2s !important;
    }

    img {
        transform: scale(1);
        object-fit: inherit !important;
        height: inherit !important;
        margin: auto;
        bottom: 0;
    }

    .gatsby-image-wrapper {
        background: linear-gradient(to bottom right, ${props => props.color_top}, ${props => props.color_bottom} );
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @media ${device.phone}{
            height: auto !important;
        }
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
`

const ProjectsListItem = ({url, color_top, color_bottom, image, title, description}) => {

    const [width, setWidth] = useState()
    const content = useRef()

    const thrs = 0.3
    const [ref, entry] = useIntersect({
        threshold: thrs
    });
    
    const [show, setShow] = useState(false)

    const props = useSpring({
        from: {
            opacity: 0, 
            transform: `translateY(200px)`,
          },
          to: {
            opacity: show ? 1 : 0,
            transform: `translateY(${show ? 0 : 200}px)`
          },
          onRest: show === false && entry.isIntersecting === true ? setShow(true) : null
      })

    //Retrieve the width of the item to calculate height

    useEffect( () => {
        if(typeof window !== 'undefined') {
            setWidth(content.current && content.current.offsetWidth)
            window.addEventListener("resize", () => {
                setWidth(content.current && content.current.offsetWidth)
            })
        }
    }, [] )

    return (
        <ProjectsItemContainer href={url} width={width} ref={ref}>

            <animated.div style={props}>

                <ProjectsItemContainerContent
                    ref={content}
                    color_top={color_top}
                    color_bottom={color_bottom}
                >

                    <Img
                        className="projectImage"
                        fluid={image}
                        alt={title}
                    />

                    <h2>{title}</h2>
                    <p>{description}</p>

                </ProjectsItemContainerContent>

            </animated.div>

        </ProjectsItemContainer>
    )
}

export default ProjectsListItem